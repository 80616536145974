import { decrypt } from '../../utils/app'
import { makeRequest } from '../../composables/useKftFetch'
import type {
  TCountQuestionResponse,
  TCountQuestionsParams,
  TCreateQuestionComment,
  TGetQuestionsResponse,
  TQuestion,
  TQuestionCommentResponse,
  TQuestionCommentsResponse,
  TQuestionSuggestResponse,
  TQuestionsParams,
} from './question.types'

export async function getQuestions(params: TQuestionsParams) {
  const runtime = useRuntimeConfig()
  const data = await makeRequest<TGetQuestionsResponse>(
    '/api/questions/v2/search',
    {
      params,
    },
  )
  return decrypt(
    data?.data?.value?.encryptedData ?? '',
    import.meta.server
      ? runtime.hashCodeInterview
      : runtime.public.hashCodeInterviewClient,
  )
}

export async function getCountQuestions(params?: TCountQuestionsParams) {
  const data = await makeRequest<TCountQuestionResponse, number>(
    '/api/questions/count-question',
    {
      params,
      transform(res) {
        return res?.count || 0
      },
    },
  )
  return data
}

export async function getQuestionDetail(slug: string) {
  const data = await makeRequest<TQuestion>(`/api/questions/detail/${slug}`)
  return data
}

export async function getAnswerBySlug(slug: string) {
  const runtime = useRuntimeConfig()
  const data = await makeRequest<TQuestion>(`/api/questions/answer/${slug}`)
  return decrypt(
    data?.data?.value?.encryptedData ?? '',
    import.meta.server
      ? runtime.hashCodeInterview
      : runtime.public.hashCodeInterviewClient,
  )
}

export async function getQuestionsSuggest(
  slugCategory: string,
  slugQuest: string,
  limit: number,
) {
  const params = {
    limit,
  }
  const data = await makeRequest<TQuestionSuggestResponse>(
    `/api/questions/v2/${slugCategory}/suggest/${slugQuest}`,
    {
      params,
    },
  )
  return data
}

export async function putMarkReadQuestion(
  questionId: string,
  isMarkRead: boolean,
) {
  const data = await makeRequest<TQuestion[]>(
    `/api/question-users/me/${questionId}`,
    {
      method: 'PUT',
      body: {
        isMarkRead,
      },
    },
  )
  return data
}

export async function getQuestionComments(slug: string) {
  const data = await makeRequest<TQuestionCommentsResponse>(
    `/api/questions/comments/${slug}`,
  )
  return data
}

export async function questionCreateComment(payload: TCreateQuestionComment) {
  const data = await makeRequest<TQuestionCommentResponse>(
    '/api/questions/comment',
    {
      method: 'POST',
      body: payload,
    },
  )
  return data
}
